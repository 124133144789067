import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash'

import { LandingPage } from './pages/LandingPage/LandingPage';
import { LangToggle } from './components/LangToggle/LangToggle';
import { Footer } from './components/Footer/Footer';

export const Main = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname.split('/')

    const [data, setData] = useState<any>(null)
    const [lang, setLang] = useState('en')
    const [translatedData, setTranslatedData] = useState<any>(null)

    const toggleLanguage = (language: string): void => {
        setLang(language)
    }

    useEffect(() => {
        // fetchData()
        if (window.location.href === 'https://passport.gg/' || window.location.href === 'https://about.passport.gg/' ||  window.location.href === 'https://www.passport.gg/') {
                          window.location.replace('https://about.passport.gg/')
                      } else {
                          window.location.replace('https://about.passport.gg/errorpage')
                      }
    }, [])

    // console.log('lang', lang);


    useEffect(() => {
        if (data) {
            // console.log(translatedData?.textTitle[0]);
            let landingTitle
            for (const key in translatedData?.textTitle[0]) {
                if (key === translatedData.mainLandingLang) {
                    landingTitle = translatedData?.textTitle[0][key]
                } else {
                    landingTitle = 'Passport.gg – is a unique tool for gamers, empowering daily activities. Play games, receive tangible and intangible benefits and have fun!'
                }
            }
            let landingDesc
            for (const key in translatedData?.textDescription[0]) {
                if (key === translatedData.mainLandingLang) {
                    landingDesc = translatedData?.textDescription[0][key]
                } else {
                    landingDesc = 'Passport.gg – is a unique tool for gamers, empowering daily activities.'
                }
            }
            const link: any = document.querySelector("link[rel~='icon']");
            link.href = translatedData?.landingFavicon;
            const title: any = document.getElementsByTagName('title')[0]
            title.innerText = translatedData?.textTitle && landingTitle
            const description: any = document.querySelector('meta[name="description"]')
            description.content = translatedData.textDescription && landingDesc
        }
    }, [translatedData, lang]);

    useEffect(() => {
        filterDataByLang(lang, data)
    }, [lang])

    const filterDataByLang = (lang: string, fetchedData: any) => {
        const langInUppercase = lang.toUpperCase();
        const newData = cloneDeep(fetchedData)
        for (const prop in newData) {
            if (Array.isArray(newData[prop])) {
                const isArrayOfStrings = typeof (newData[prop][0]) === 'string' || typeof (newData[prop][0]) === 'number'
                if (!isArrayOfStrings) {
                    const filteredByLang = newData[prop].filter((item: any) => {
                        if (item !== null && !Array.isArray(item) && typeof item === 'object') {
                            for (const key in item) {
                                if (key === langInUppercase) {
                                    return item
                                }
                            }
                        }
                    })
                    newData[prop] = filteredByLang
                }
            }
        }
        setTranslatedData(newData)
    }

    // const fetchData = async () => {
    //     try {
    //         const query = url.hostname.split('.')[0]
    //         const response = await axios.get(`https://newapi.passport.gg/public/v1/landingUrl/${query}`)
    //         setLang(response.data.mainLandingLang)
    //         filterDataByLang(response.data.mainLandingLang, response.data)
    //         setData(response.data)
    //     } catch (e) {
    //         try {
    //             const query = pathname[pathname.length - 1];
    //             const response = await axios.get(`https://newapi.passport.gg/public/v1/landingUrl/${query}`)
    //             setLang(response.data.mainLandingLang)
    //             filterDataByLang(response.data.mainLandingLang, response.data)
    //             setData(response.data)
    //         } catch (e: any) {
    //             if (window.location.href === 'https://passport.gg/' || window.location.href === 'https://about.passport.gg/') {
    //                 window.location.replace('https://about.passport.gg/')
    //             } else {
    //                 window.location.replace('https://about.passport.gg/errorpage')
    //             }
    //         }
    //     }
    // }

    return (
<div>Loading</div>
        // <div>
        //     {data && <div><header style={{
        //         width: '100%',
        //         height: '80px',
        //         background: 'rgb(63, 49, 124)',
        //         padding: '20px 0',
        //         display: 'flex',
        //         justifyContent: 'space-between',
        //         alignItems: 'center',
        //         paddingRight: 24
        //     }}>
        //         <div>
        //             <a href="https://about.passport.gg">
        //                 <img src="https://static.tildacdn.com/tild3835-3564-4230-a637-343266643235/logo-passportgg.png" style={{ maxWidth: '300px', width: '300px', minWidth: '300px', height: 'auto', display: 'block' }} alt="passport.gg"></img>
        //             </a>
        //         </div>
        //         {data && <LangToggle btnText={data?.textBlockButtons5} color={data?.mainColor} langs={data?.languages} toggleLanguage={toggleLanguage} selectedLang={lang} />}
        //     </header>
        //         {translatedData && <LandingPage data={translatedData} />}
        //         <Footer /></div>}
        // </div>
    )
}
